<template>
  <button
    class="px-2 py-1 inline-flex items-center space-x-1 text-xs font-medium rounded-sm bg-black text-white whitespace-nowrap"
    type="button"
    @click="onClick"
  >
    <span v-if="blockedData?.labelText" :data-attribute="blockedData?.attribute">{{
      blockedData.labelText
    }}</span>
    <ArrowUpRightIcon class="w-4 h-4" />
  </button>
</template>

<script setup>
import { ArrowUpRightIcon } from "@heroicons/vue/20/solid"

const props = defineProps({
  blockedData: Object, // {labelText: 'My label', labelAction: function}
})
const onClick = () => {
  if (props.blockedData?.labelAction) {
    props.blockedData.labelAction()
  }
}
</script>
